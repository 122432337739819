<template>
  <transition name="fade" appear>
    <div class="home">
      <el-row class="search" >
        <el-col :span="21"
          ><input type="text" placeholder="请输入" class="et_search" v-model="keyWords" @keyup.enter="getList"
        /></el-col>
        <el-col :span="3"><button class="search_text" @click="getList()">搜索</button></el-col>
      </el-row>

      <section class="navBox">
        <div class="show" v-show="!show"><el-empty description="暂无数据"></el-empty></div>
        <template v-for="(item, index) in activityList">
          <div class="nav_item_festival" :key="index" @click="goHolidayDetail(index)">
            <div class="navItem_img_festival hoverBox1">
              <el-image :src="item.imageUrl" fit="fill" style="height: 100%; width: 100%" class="pointer">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
            <div class="navItem_text__festival hoverText">{{ item.activityName }}</div>
             <div class="navItem_text__festival hoverText" style="font-size:8px;margin-left:3%;">活动时间:{{item.activityStartTime|dataFormat}}至{{item.activityEndTime|dataFormat}}</div>
            <!-- <div class="navItem_text__festival2 hoverText">{{ item.syllabusTypeName }}</div> -->
          </div>
        </template>
      </section>
      <el-pagination
        v-show="show"
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="handleCurrentChange"
        :current-page.sync="currPage"
      >
      </el-pagination>

      <!-- <div class="point"><el-pagination background="" layout="prev, pager, next" :total="1000"> </el-pagination></div> -->
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  name: "holiday",
  data() {
    return {
      //数据总量
      total:0,
      //单页数量
      pageSize: 15,
      //当前页数
      currPage: 1,
      //分类下标
      typeIndex: 0,
      keyWords: "",
      title: "公共管理学课程教学大纲",
      time: "2022年2月14日",
      filterUrl: "http://banktrain.pluss.cn/upload/file_202202240916941.pdf",
      // 节日活动
      activityList: [],
      //是否有数据
      show: false,
      userInfo:{},
    };
  },
  methods: {
    handleCurrentChange(){

    },
    //分类查询活动
    getList() {
      var that = this;
      var getData = {};
      var condition = {};
      condition.activityName = that.keyWords;
      let promptCode = JSON.parse(sessionStorage.getItem("userInfo")).promptCode; 
      if(promptCode == "jk"){
        condition.promptCode = "jk"
      }else{
        condition.promptCode = "rz"
      }
      getData.condition = condition;
      getData.pageSize = 100;
      getData.currPage = this.currPage;
      this.$api.home.getActivityByName(getData).then((res) => {
        if (res.data.code === 200) {
          this.activityList = res.data.data.data;
          // console.log('数据', this.activityList)
          this.total = res.data.data.count;
          if (this.total > 0) {
            this.show = true;
          } else {
            this.show = false;
          }
        }
      });
    },

    // //获取活动列表
    // getActivityUrl() {
    //   var getData = {};
    //   getData.pageSize = this.pageSize;
    //   getData.currPage = this.currPage;
    //   this.$api.home.getActivityList(getData).then((res) => {
    //     if (res.data.code === 200) {
    //       this.activityList = res.data.data.data;
    //       this.total = res.data.data.count;
    //     }
    //   });
    // },
    downLoad() {
      //下载
      window.open("http://banktrain.pluss.cn/upload/file_202112074097231.Xls");
    },

    // 前往节日活动详情
    goHolidayDetail(index) {
      
       //当前时间
      var currentTime = Date.parse(new Date());
      console.log('this.userInfo',this.userInfo);
      if (this.userInfo!=null&&this.userInfo.expirationTime) {
        //此处待开放
        if (currentTime - this.userInfo.expirationTime >= 0) {
          // this.open();
           this.$message({
            type: "error",
            message: "您的课程已过期，请前往个人中心重新激活后使用",
            offset:'400',
          });
        } else {
        this.$router.push({
        path: "/holidayDetail",
        query: {
          activityCode: this.activityList[index].activityCode,
        },
      });
      }
      } else {
        // this.open();
          this.$message({
            type: "error",
            message: "您未登录或您的课程暂未激活，请前往个人中心激活后使用",
            offset:'400',
          });
      }
    },
  },
  created() {
     this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.$store.commit(CHANGE_NAVINDEX, "0");
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 650px;
  margin-top: 40px;
  padding: 0 50px;
  background-color: white;
  .search {
    border: 1px solid #fab300;
    border-radius: 6px;
    display: flex;
    height: 53px;
    align-items: center;
    .et_search {
      outline: none;
      font-size: 18px;
      width: 100%;
      padding-left: 30px;
      align-items: flex-start;
      border: none;
    }
    .search_text {
      height: 53px;
      background-color: #fab300;
      border-radius: 6px;
      align-items: center;
      width: 100%;
      border: none;
      line-height: 53px;
      vertical-align: middle;
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .navBox {
    display: flex;
    // padding: 0 51px;
    justify-content: flex-start;
    align-content: flex-start;
    min-height: 480px;
    flex-flow: wrap;
    .show {
      height: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .nav_item_festival {
      display: flex;
      width: 20%;
      // height: 150px;
      height: auto;
      margin: 20px 0 0 0;
      flex-direction: column;
      align-items: center;
      .navItem_img_festival {
        height: 98px;
        width: 98px;
        border-radius: 6px;
        overflow: hidden;
      }

      .navItem_text__festival {
        margin-top: 9px;
        font-size: 14px;
        color: #000000ff;
      }

      .navItem_text__festival2 {
        margin-top: 8px;
        font-size: 14px;
        color: #000000ff;
      }
    }
  }
  .point {
    text-align: right;
    margin: 20px;
    margin-right: 40px;
  }
}
</style>
